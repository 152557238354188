.head-text {
    position: relative;
}

.text-on-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 15%;
}