#footer {
    background-color: #000000;
    color: #ffffff;
    height: 140px;
}

.footer-copy-right {
    font-size: small;
}

.powered-by {
    text-decoration: none;
    color: #EDD4AE;
}

.powered-by:hover {
    text-shadow: 2px 0px 10px #ffffff;
}