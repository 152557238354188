#home-header {
    background-image: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url('../static/home-header-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.home-header-animation {
    animation: 5000ms ease 100ms 1 normal both running tectonic-LEFT-e6d0b4b0-5f83-48cb-ae08-2113d1fc3b10, 400ms linear 0s 1 normal both linear_fade;
}

.header-description {
    font-size: large;
}

@keyframes tectonic-LEFT-e6d0b4b0-5f83-48cb-ae08-2113d1fc3b10 {
    0% {
        transform: translateX(calc(-0.16666666666666666 * min(1366px, 100vw)));
        animation-timing-function: cubic-bezier(0.47, 0.03, 0.53, 0.97);
    }

    50% {
        transform: translateX(calc(0.08333333333333333 * min(1366px, 100vw)));
        animation-timing-function: cubic-bezier(0.47, 0.03, 0.53, 0.97);
    }

    100% {
        transform: translate(0px, 0px);
    }
}

@keyframes linear_fade {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}