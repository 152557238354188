body,
html {
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.cardo-font-family {
    font-family: Cardo;
    font-weight: 700;
}

.cardo-font-family-shadow {
    text-shadow: 0em 0.0375em 0.2325em rgba(0, 0, 0, 0.490000);
}

.purple-button {
    border-radius: 0;
    background-color: #EDD4AE;
    font-size: smaller;
    font-family: 'Open Sans';
    color: #ffffff;
    font-weight: 700;
    border-color: #ffffff;
}

.purple-button:hover {
    color: #EDD4AE;
    background-color: #ffffff;
    border-color: #EDD4AE;
}

.purple-button:disabled {
    color: black;
    background-color: lightgray;
    border-color: lightgray;
}