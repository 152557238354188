.home-contact-form-input {
    border-radius: 0;
    background-color: #EDD4AE;
    color: #000000;
    font-size: smaller;
    font-family: 'Open Sans';
    font-weight: 700;
}

.home-contact-form-alert {
    border-radius: 0;
    color: #000000;
    font-size: smaller;
    font-family: 'Open Sans';
    font-weight: 700;
}