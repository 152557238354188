.header-menu-lift {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: #D8DDE8;
}

.offcanvas-title {
    width: 100%;
    text-align: center;
}

.offcanvas-title .navbar-text {
    margin-left: 30px;
}

.navbar-text .social-media-link {
    padding: 0 0 0 20px;
}

.navbar-toggler {
    border: none;
}

.offcanvas .navbar-nav .nav-link {
    border-radius: 0;
    padding: 5px 20px 5px 20px;
    text-align: center;
    color: black;
}

.offcanvas .navbar-nav .nav-link:hover {
    background-image: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('./static/nav-item-bg.png');
    color: black;
}

.offcanvas .navbar-nav .nav-link.active {
    background-color: #EDD4AE;
}

.offcanvas.offcanvas-end {
    width: 100%;
    border-left: none;
}